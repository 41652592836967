import React from 'react'
import { Col, Row } from 'react-bootstrap'

function EducationItem({from, to, title, institute}) {
    return (
        <Row className="pt-4">
            <Col>
                <p className="text-black-50 mb-0">
                    {from}
                    {to ? (<span> — {to} </span>) : null}</p>
                <p className="bold jobTitle mb-0">{title}</p>
                <p className="mb-1">
                    <span className="bold">{institute.name}</span>
                    {institute.desc ? 
                        (<span className="text-black-50"> —  {institute.desc}</span>) : null
                    }
                </p>
            </Col>
        </Row>
    )
}

export default EducationItem

import React from 'react'
import Section from './Section'
import EducationItem from './EducationItem'
import { Col, Row } from 'react-bootstrap'
function EducationSection() {
    return (
        <>
            <Section
                name="Education"
            >
                <Row>
                <Col md={6} sm={12}>
                    <EducationItem
                        from="Mar, 2016"
                        to="Present"
                        title="Information Systems Engineer"
                        institute={{
                            name: "Universidad Tecnológica Nacional"
                        }}
                    />
                    <EducationItem
                        from="2010"
                        to="2015"
                        title="Computer Science Technical Diploma"
                        institute={{
                            name: "ORT Argentina",
                        }}
                    />     
                </Col>
                <Col md={6} sm={12}>
                        <EducationItem
                            from="Mar, 2019"
                            title="UI / UX Design"
                            institute={{
                                name: "Coderhouse"
                            }}
                        />
                        <EducationItem
                            from="Dec, 2012"
                            title="Preliminary English Test"
                            institute={{
                                name: "University of Cambridge"
                            }}
                        />
                </Col>
                </Row>           
            </Section>
        </>
    )
}

export default EducationSection

import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimatedSection = ({children}) => {
    const controls = useAnimation();
    const { ref, inView } = useInView();
    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (!inView) {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
         <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
              transition={{ ease: "easeInOut" , duration: 0.5}}
            variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0.5, scale: 0.75},
            }}
        >
            {children}
        </motion.div>
    )
}

export default AnimatedSection

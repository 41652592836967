import React from 'react'
import { motion } from 'framer-motion'

function AnimatedPage(props) {
    const config = {
        type: "spring",
        damping: 20,
        stiffness: 100
    };
    return (
        <motion.div
            transition={config}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ x: 0, opacity: 0 }}
        >
            {props.children}
        </motion.div>
    )
}

export default AnimatedPage

import React from 'react'
import Section from './Section'
import { Col, Row } from 'react-bootstrap'
function SkillsSection() {
    return (
        <>
            <Section
                name="Skills"
            >
                <Row>
                    <Col >
                        <p className="bold jobTitle">Technologies & Tools</p>
                        <p>Git — Linux — PHP — MySQL — JS — PostgreSQL — HTML — CSS/SASS — Bootstrap — Apache — JIRA — Adobe Creative Suite</p>
                    </Col>
                </Row>   
                <Row>
                    <Col >
                        <p className="bold jobTitle">Languages</p>
                        <p>Spanish <span className="text-black-50">(Native)</span> — English <span className="text-black-50">(Working Professional)</span></p>
                    </Col>
                </Row>  
                <Row>
                    <Col >
                        <p className="bold jobTitle">Other skills</p>
                        <p>Web design — Web Development — UI/UX Design — Agile — Scrum — Empathy — Team player</p>
                    </Col>
                </Row>           
            </Section>
        </>
    )
}

export default SkillsSection

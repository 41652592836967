import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
function Section(props) {
    return (
        <Container className="p-5">
            <Row className="align-items-center">
                <Col >
                    <h2>{props.name}</h2>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col>
                    {props.children}
                </Col>
            </Row>
        </Container>
    )
}

export default Section

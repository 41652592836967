import React from 'react'
import { Row, Col, Form, Button, Alert, Toast } from 'react-bootstrap'
import AnimatedPage from './AnimatedPage'
import BouncingBall from '../components/BouncingBall'
import {motion} from 'framer-motion'
import Section from '../components/Section'
const Contact = () => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [savedSuccess, setSavedSuccess] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const changeName = (e) => { setName(e.target.value) }
    const changeEmail = (e) => { setEmail(e.target.value) }
    const changeMessage = (e) => { setMessage(e.target.value) }
    const [validated, setValidated] = React.useState(false);
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    const onFormSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false || !name || !email || !validateEmail(email) || !message) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            setLoading(true);
            setSavedSuccess(false)
            setErrorMsg(false)
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute('6LdvtJIaAAAAAFhr0g6HWV5gr1GExk2MURw1E4RC', { action: 'submit' }).then(function (token) {
                    // enviamos al be
                    let formData = new FormData();
                    formData.append('name', name);
                    formData.append('email', email);
                    formData.append('message', message);
                    formData.append('token', token);

                    fetch("send.php",
                        {
                            body: formData,
                            method: "post"
                        })
                        .then(response => response.json())
                        .then((res) => {
                            setLoading(false)
                            setSavedSuccess(res.valid)
                        }).catch((error) => {
                            setLoading(false)
                            setSavedSuccess(false)
                            setErrorMsg(true)
                        });
                });
            });

        }
        setValidated(true);

    }
    return (
        <AnimatedPage>
            <Section
                name="Contact me"
            >
                {errorMsg ? (<div
                    aria-live="polite"
                    aria-atomic="true"
                    style={{
                        position: 'absolute',
                        top: 0, right: 0, zIndex: 100,
                        minHeight: '100px',
                        minWidth: '200px'
                    }}
                >
                    <Toast className="bg-danger text-white" onClose={() => setErrorMsg(false)} show={errorMsg} delay={100000} autohide style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}>
                        <Toast.Header>
                            <strong className="mr-auto">Oops</strong>
                            <small>Now</small>
                        </Toast.Header>
                        <Toast.Body>There has been an error sending your message. Please, try again later.</Toast.Body>
                    </Toast>
                </div>) : null}
                <Row>
                    <Col sm={12}>
                        {savedSuccess ? (
                            <motion.div  animate={{ opacity: 1 }} initial={{ opacity: 0 }}
    transition={{ duration: 0.5 }}>
                            <Alert variant="success">
                                <Alert.Heading>Thanks!</Alert.Heading>
                                <p>Your message has been sent successfully.</p>
                            </Alert>
                            </motion.div>
                        ) : (loading ? (
                            <>
                                <Row className="pt-5 pb-5"><Col xs={1}><BouncingBall /></Col><Col xs={11}><h3>Sending ...</h3></Col></Row>
                                </>
                        ) : (<>
                            <p>Please, fill this form and I'll reach out ASAP.</p>
                            <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="" value={name} onChange={changeName} required />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter your name.
                                </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" required placeholder="me@example.com" value={email} onChange={changeEmail} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid email address.
                                </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" required rows={3} value={message} onChange={changeMessage} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a message.
                                </Form.Control.Feedback>
                                </Form.Group>

                                <Button variant="dark" type="submit" onClick={e => onFormSubmit(e)}>
                                    Submit</Button>
                            </Form>
                        </>))}
                    </Col>
                </Row>
            </Section>

        </AnimatedPage>
    )
}

export default Contact

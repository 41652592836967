import React from 'react'
import AnimatedPage from './AnimatedPage'
import Bio from '../components/Bio'
import EducationSection from '../components/EducationSection'
import WorkExperienceSection from '../components/WorkExperienceSection'
import SkillsSection from '../components/SkillsSection'
import ContactCTA from '../components/ContactCTA'
import AnimatedSection from '../components/AnimatedSection'
function Home() {
    return (
        <AnimatedPage>
            <AnimatedSection>
                <Bio />
            </AnimatedSection>
            <AnimatedSection>
                <WorkExperienceSection />
            </AnimatedSection>
            <AnimatedSection>
                <EducationSection />
            </AnimatedSection>
            <AnimatedSection>
                <SkillsSection />
            </AnimatedSection>
            <AnimatedSection>
                <ContactCTA />
            </AnimatedSection>
        </AnimatedPage>
    )
}

export default Home

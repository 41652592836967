import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarker } from '@fortawesome/free-solid-svg-icons'
function Bio() {
    return (
        <Container>
            <Row className="align-items-center">
                <Col xs={12} md={8} className="p-5">
                    <h1>Marcelo Rajñansky,<br />
            Web developer</h1>
                </Col>
                <Col xs={12} md={4} className="p-5">
                    <p><a href="mailto:me@mrajnansky.com"><FontAwesomeIcon icon={faEnvelope} className="mr-2" />me@rajnansky.com</a></p>
                    <p><FontAwesomeIcon icon={faMapMarker} className="mr-2" />Madrid, Spain</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={8} className="p-5">
                    <p>As a web developer I enjoy working in projects I find interesting, with creative people and the opportunity to learn. I always push myself to give something extra to all my works, delivering a better customer experience and building long term relationships. From each work I do, I aim to grow professionally and personally.</p></Col>
            </Row>
        </Container>
    )
}

export default Bio

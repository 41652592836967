import React from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function ContactCTA() {
    return (
        <Container fluid className="blackBg">
            <Container className="p-5">
                <Row className="align-items-center">
                    <Col xs={12} md={6}>
                        <h2>I'm open to hear new opportunities</h2>
                        <p>I'll be happy to hear that you are considering me for an opening. Feel free to connect!</p>
                        
                    </Col>
                    <Col xs={12} md={6} className="text-md-center">
                       <Button size="lg" as={Link} to="/contact" variant="outline-dark text-white">Contact me <FontAwesomeIcon icon={faArrowRight}/></Button>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default ContactCTA

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import {Container, Row, Col} from 'react-bootstrap'
import Home from './pages/Home'
import Menu from './components/Menu'
import Contact from './pages/Contact'

function App() {
  return (
    <>
      <Router>
        <Menu/>
        <Route
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter initial={false}>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/" component={Home} />
                <Route exact path="/contact" component={Contact} />
              </Switch>
            </AnimatePresence>
          )}
        />
      </Router>
        <Container fluid className="grey-bg">
          <Container className="p-5">
            <Row>
              <Col>
            <p>Copyright &copy; 2021 Marcelo Rajñansky.</p>
            </Col>
            </Row>
          </Container>
        </Container>
        
    </>
  );
}

export default App;

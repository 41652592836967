import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'

function WorkExperienceItem({from, to, jobTitle, employer, jobDescription, links}) {
    return (
        <Row className="pt-4">
            <Col>
                <p className="text-black-50 mb-0">{from} — {to}</p>
                <p className="bold jobTitle mb-0">{jobTitle}</p>
                {employer.img ? (
                    <Row className="align-items-center mb-0">
                        <Col sm={2} md={1}>
                            <Image src={employer.img} style={{maxWidth:"50px"}}/> 
                        </Col>
                        <Col sm={10} md={11}>
                            <span className="bold">{employer.name}</span>{employer.desc ? " — " + employer.desc : null}
                        </Col>
                    </Row>
                ):(
                    <>
                        <p className="mb-1">
                            <span className="bold">{employer.name}</span>
                            {employer.desc ? (<span className="text-black-50"> —  {employer.desc}</span>) : null}</p>
                    </>
                )}
                <p>{jobDescription}</p>
            </Col>
        </Row>
    )
}

export default WorkExperienceItem

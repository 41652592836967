import React from 'react'
import Section from './Section'
import WorkExperienceItem from './WorkExperienceItem'
function WorkExperienceSection() {
    return (
        <>
            <Section
                name="Work experience"
            >
                <WorkExperienceItem
                    from="Sep, 2022"
                    to="Present"
                    jobTitle="Technical Leader"
                    employer={{
                        name: "Verifarma",
                        desc: "Digital solutions provider for pharmaceutical companies"
                    }}
                    jobDescription="In charge of the technical team for Verifarma Integrated Solutions"
                />
                <WorkExperienceItem
                    from="Feb, 2020"
                    to="Sep, 2022"
                    jobTitle="Full Stack Web Developer"
                    employer={{
                        name: "Verifarma",
                        desc: "Digital solutions provider for pharmaceutical companies"
                    }}
                    jobDescription="Mantained a pharmaceutical products traceability solution and worked on the creation of a LIMS and QMS solution."
                />
                <WorkExperienceItem
                    from="Aug, 2017"
                    to="Feb, 2020"
                    jobTitle="Full Stack Web Developer"
                    employer={{
                        name: "ORT Argentina",
                        desc: "Non-government organization devoted to education"
                    }}
                    jobDescription="Improved its virtual classroom functionality and usability, where teachers post homework and tests for their students and also a window for the families of the community showing them what's happening inside the school."
                />
                <WorkExperienceItem
                    from="Feb, 2016"
                    to="Aug, 2017"
                    jobTitle="Full Stack Web Developer"
                    employer={{
                        name: "Softliness",
                        desc: "Software factory"
                    }}
                    jobDescription="My role involved tasks as design, write and modify websites and database models to fit local customers necessities as of a presentation website (i.e. About the company and Contact us) or a complete management system for their finances, cashflow, stock, production and more."
                />

                {/*<Row className="pt-4">
                    <Col>
                        <Button variant="outline-dark">See my freelancer portfolio</Button>
                    </Col>
                </Row>*/}
            </Section>
        </>
    )
}

export default WorkExperienceSection

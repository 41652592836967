import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';
import {motion, useSpring, useTransform} from 'framer-motion'
import {Link} from 'react-router-dom'

function Menu() {
    const x = useSpring(3, {velocity: 1000});
    setInterval(() => x.set(Math.random() * 100), 5000)
    const background = useTransform(x, [0, 100], ["#09203f", "#537895"])
    return (
        <>
            <Navbar collapseOnSelect fixed="top" expand="sm" bg="white" variant="light">
                <Container className="pl-5 pr-5">
                    <Navbar.Brand as={Link} to="/"><motion.h3 style={{
                color: background
            }}>mr</motion.h3></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav>
                            <Nav.Link as={Link} to="/">CV</Nav.Link>
                            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="mb-5"></div>
        </>
    )
}

export default Menu

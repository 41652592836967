import React from "react";
import { motion } from "framer-motion";

const ballStyle = {
    display: "block",
    width: "1rem",
    height: "1rem",
    backgroundColor: "black",
    borderRadius: "0.5rem"
};

const bounceTransition = {
    y: {
        duration: 0.4,
        yoyo: Infinity,
        ease: "easeOut"
    },
    backgroundColor: {
        duration: 0,
        yoyo: Infinity,
        ease: "easeOut",
        repeatDelay: 0.8
    }
};

export default function BouncingBall() {
    return (
        <div
            style={{
                width: "2rem",
                height: "2rem",
                display: "flex",
                justifyContent: "space-around"
            }}
        >
            <motion.span
                style={ballStyle}
                transition={bounceTransition}
                animate={{
                    y: ["100%", "-100%"],
                    backgroundColor: ["#09203f", "#537895"]
                }}
            />
        </div>
    );
}